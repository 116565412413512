body, html {width: 100%; height: 100%; margin: 0; padding: 0}

.App {
  font-family: 'Arial', sans-serif;
  text-align: center;
  background-color: #f5f5f5;
  min-height: 100vh;
}

/* Navigation styles */
.navbar {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #282c34;
  padding: 1em;
  /*margin-bottom: 2em;*/
}

.navbar ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.navbar li {
  display: inline;
}

.navbar a {
  color: white;
  text-decoration: none;
  padding: 0.5em 1em;
}

.navbar a:hover {
  background-color: #555;
  transition: background-color 0.3s;
}
.container{
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.mapframe{
  flex: 1;
  width: 100%;
  border: none;
}